import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

function BinanceProfits() {
const [poloniexProfits, setPoloniexProfits] = useState([]);
const [hoverIndex, setHoverIndex] = useState(null);

useEffect(() => {
const fetchData = async () => {
const response = await axios.get('https://tradelogs-553b7-default-rtdb.firebaseio.com/BINANCE_PROFITS.json');
if (response.data && typeof response.data === 'object') { // Verifica se o objeto não é nulo ou indefinido
const profits = Object.values(response.data);
const sortedProfits = profits.sort((a, b) => parseFloat(b.WHEN_ts) - parseFloat(a.WHEN_ts)); // Ordena em ordem decrescente com base no campo WHEN_ts convertido em double
setPoloniexProfits(sortedProfits);
}
};
fetchData();
}, []);

const getBackgroundColor = (side) => {
if (side === 'SELL') {
return 'lime';
} else if (side === 'BUY') {
return 'orange';
}
};

const handleMouseEnter = (index) => {
setHoverIndex(index);
};

const handleMouseLeave = () => {
setHoverIndex(null);
};

const ulStyles = {
width: '210px',
overflowY: poloniexProfits.length > 10 ? 'scroll' : 'auto',
height: poloniexProfits.length > 10 ? '470px' : 'auto',
textAlign: 'left',
cursor: 'pointer',
};

const formatDate = (dateString) => {
const date = new Date(dateString);
return date.toLocaleDateString();
};

return (
<div>
<h4 style={{ color: 'yellow' }}>
<FormattedMessage id="binance.profits" />
</h4>
<ul style={ulStyles}>
{Array.isArray(poloniexProfits) &&
poloniexProfits.map((profit, index) => (
<li
key={index}
style={{
backgroundColor: getBackgroundColor(profit.side),
}}
onMouseEnter={() => handleMouseEnter(index)}
onMouseLeave={handleMouseLeave}
>
{hoverIndex === index && (
<>
{profit.side === 'SELL' && (
<>
USDT cash back: {profit.quantity_usdt_on_sell_profit}
<br />
USDT Total: {profit.quantity_usdt_on_sell_total}
<br />
Bot: {profit.bot}
<br />
</>
)}
{profit.side === 'BUY' && (
<>
BTC profit: {profit.quantity_btc_on_buy_profit}
<br />
Bot: {profit.bot}
<br />
Date: {formatDate(profit.WHEN_dt)} <br />
</>
)}
</>
)}
<>
<table style={{ width: '100%' }}>
<tr>
<td>
{profit.side} : {profit.price}
</td>
<td style={{ textAlign: 'right' }}> {profit.bot} </td>
</tr>
</table>
</>
</li>
))}
</ul>
</div>
);
}

export default BinanceProfits;