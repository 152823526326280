import React from 'react';

import MeuComponenteBots from './topbar_divs';
import MeuComponenteBots2 from './toolbar_divs_bots';

import TradingViewWidget from './GCHART1';
import TradingViewWidget_Poloniex from './GCHART_POLONIEX';
import Rodape from './Rodape';
import PoloniexProfits from './firebase_poloniex_profits';
import BinanceProfits from './firebase_binance_profits';

import PoloniexChartAnalytics1 from './BOTAnalytics_polo1/botanalyticspolo1';
import AnalysisComponent from './TECANALYSIS_bots/binance_7_days_analysis';


function PG_HOME() {
  return (
    <>
      <MeuComponenteBots />
      <br />
      <table style={{ width: '100%', height: '100%' }}>
        <thead></thead>
        <tbody>
        <tr>
          <td>
          <PoloniexChartAnalytics1 />
          </td>
        </tr>
          <tr>
            <td>
              <TradingViewWidget />
            </td>
            <td style={{ 'verticalAlign': 'text-top', 'width': '200px' }}>
              <BinanceProfits />
            </td>
            <td>
              <TradingViewWidget_Poloniex />
            </td>
            <td style={{ 'verticalAlign': 'text-top', 'width': '200px' }}>
              <PoloniexProfits />
            </td>
          </tr>
        </tbody>
      </table>
      <MeuComponenteBots2 />
      <AnalysisComponent />
      <Rodape />
    </>
  );
}

export default PG_HOME;
