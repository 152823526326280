import React, { useState, useEffect } from "react";
import axios from "axios";

function PoloniexChartAnalytics1() {
  const [chartData, setChartData] = useState([]);
  const [startTime, setStartTime] = useState(Math.floor(Date.now() / 1000) - 4 * 60 * 60);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`https://cors-anywhere.herokuapp.com/https://poloniex.com/public?command=returnChartData&currencyPair=USDT_BTC&start=${startTime}&end=9999999999&period=900`);
        setChartData(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    const interval = setInterval(() => {
      setStartTime(Math.floor(Date.now() / 1000) - 4 * 60 * 60);
    }, 10000);

    fetchData();

    return () => clearInterval(interval);
  }, [startTime]);

  const latestValues = chartData.length > 0 && chartData[chartData.length - 1];

  return (
    <>
      {latestValues && (
        <div>
          Últimos valores:
          <ul>
            <li>Abertura: {latestValues.open}</li>
            <li>Máxima: {latestValues.high}</li>
            <li>Mínima: {latestValues.low}</li>
            <li>Fechamento: {latestValues.close}</li>
          </ul>
        </div>
      )}
    </>
  );
}

export default PoloniexChartAnalytics1;
