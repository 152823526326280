import React, { useState, useEffect } from 'react';
import Advisor_polobot3 from './firebase_poloniex_advisor_bot3';

function WalletInfo() {
const [records, setRecords] = useState([]);

useEffect(() => {
async function fetchData() {
try {
    const response = await fetch('https://tradelogs-553b7-default-rtdb.firebaseio.com/wallet_polobot3.json');
    const data = await response.json();
    const recordList = Object.values(data);
    setRecords(recordList);
} catch (error) {
    console.error(error);
}
}
fetchData();
    const interval = setInterval(() => {
    fetchData();
}, 30000);
    return () => clearInterval(interval);
}, []);

return (
<>
    {records.length > 0 ? (
    <div style={{fontSize:'12px',textAlign: 'left'}}>
        <strong style={{fontSize:'12px',textAlign: 'left', color:'lime'}}>Poloniex BOT3</strong><br />
        Profit BTC: {records[records.length -1].profit_btc} <br/>
        Profit in USDT: {records[records.length -1].profit_usdt} <br/>
        Trades: {records[records.length -1].trades} 
        <Advisor_polobot3 /> <br/><br/><br/><br/>
    </div>
    ) : (
        <p>Poloniex BOT3 waiting...</p>
    )}
</>
);
}

export default WalletInfo;
