import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function VideoPlayer() {
const [currentVideo, setCurrentVideo] = useState(null);
const intl = useIntl();
const language = intl.locale;

useEffect(() => {
const videosPtBR = [
{
url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000001_aguardandoflutuacao.mp4?raw=true',
duration: 15
},
{
url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000001_inscreva-se.mp4?raw=true',
duration: 15
},
{
url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000001.mp4?raw=true',
duration: 15
},
{
url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000001_maisbaixomelhor.mp4?raw=true',
duration: 15
},
{
url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000001_operandoeaguardando.mp4?raw=true',
duration: 15
}
];

const videosOtherLang = [
  {
    url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000002_controlledbybots.mp4?raw=true',
    duration: 15
  },
  {
    url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000002_formingchart.mp4?raw=true',
    duration: 15
  },
  {
    url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000002_ifupifdown.mp4?raw=true',
    duration: 15
  },
  {
    url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000002_lowerprice.mp4?raw=true',
    duration: 15
  },
  {
    url: 'https://github.com/DanDevel/BTCH_IA_media/blob/main/BitcoinHomeBroker000002_trendline.mp4?raw=true',
    duration: 15
  }
];

const intervalId = setInterval(() => {
  const randomVideo = language === 'pt-BR' ? 
    videosPtBR[Math.floor(Math.random() * videosPtBR.length)] : 
    videosOtherLang[Math.floor(Math.random() * videosOtherLang.length)];
  setCurrentVideo(randomVideo);
}, 60000);

return () => clearInterval(intervalId);
}, [language]);

useEffect(() => {
if (currentVideo) {
const video = document.createElement('video');
video.preload = 'auto';
video.src = currentVideo.url;
video.style.position = 'fixed';
video.style.bottom = '0';
video.style.left = '0';
video.style.width = '200px';
video.style.height = '200px';
video.style.zIndex = '9999';
document.body.appendChild(video);
video.play();
setTimeout(() => {
  video.pause();
  video.currentTime = 0;
  setCurrentVideo(null);
  document.body.removeChild(video);
}, currentVideo.duration * 1000);
}
}, [currentVideo]);

return null;
}

export default VideoPlayer;