import React, { useState, useEffect } from 'react';


function ComponenteTabColors() {
  const [cores, setCores] = useState(Array(90).fill('white'));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const novasCores = cores.map(() => {
        return gerarCorAleatoria();
      });
      setCores(novasCores);
    }, 500);
    return () => clearInterval(intervalId);
  }, [cores]);

  function gerarCorAleatoria() {
    const letras = '0123456789ABCDEF';
    let cor = '#';
    for (let i = 0; i < 6; i++) {
      cor += letras[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  const linhas = [];
  for (let i = 0; i < 10; i++) {
    const colunas = [];
    for (let j = 0; j < 9; j++) {
      const index = i * 9 + j;
      colunas.push(<td style={{ backgroundColor: cores[index] }} key={index}></td>);
    }
    linhas.push(<tr key={i}>{colunas}</tr>);
  }

  return (
    <center>
    <table className="tab-colors" style={{'width':'300px', 'height':'300px'}}>
      <tbody>
        {linhas}
      </tbody>
    </table></center>
  );
}

export default ComponenteTabColors;
