import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ComponenteTabColors from './tabela_colorida';
import { FormattedMessage, useIntl } from 'react-intl';


function AnalysisComponent() {
  const [dados, setDados] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://tradelogs-553b7-default-rtdb.firebaseio.com/BINANCE_TEC_ANALYSIS_BTC_EN_US.json');
        setDados(response.data);
        console.log('R DATA ::: ');
        console.log(response.data);
        console.log('R DATA 2::: ');
        console.log(response.data['-NUlNMPLPf4T8_FCdTh1']['interval']);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 240000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <center>
      <div style={{color:'#c12', 'width':'690px'}} >
        <h1><FormattedMessage id='analisetecnica.titulo' /></h1><br/>
        
        <ComponenteTabColors />
        <h2><FormattedMessage id='analisetecnica.youtubelink' /> <a href='https://www.youtube.com/@BitcoinHomeBroker?sub_confirmation=1' target='blank' style={{'color':'yellow'}} >@BitcoinHomeBroker</a> </h2>
        <h2><FormattedMessage id='analisetecnica.instagramlink' /> <a href='https://www.instagram.com/bitcoinhomebroker/' target='blank' style={{'color':'yellow'}} >@BitcoinHomeBroker</a> </h2>
        <p>Last update: {dados['-NUlNMPLPf4T8_FCdTh1']?.price_analysis.datetime || '---'}</p>
        <p><FormattedMessage id='analisetecnica.priceandanalytics' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.price_analysis.bull_bear_info}</p>
        <p><FormattedMessage id='analisetecnica.period' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.price_analysis.period_return}</p>
        <p><FormattedMessage id='analisetecnica.pricetrend' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.price_analysis.price_trend}</p>
        <p><FormattedMessage id='analisetecnica.pricefluctuation' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.price_analysis.price_volatility}</p>
        <p><FormattedMessage id='analisetecnica.statistics' /> </p>
        <ul>
          <li><FormattedMessage id='analisetecnica.max' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.maximum}</li>
          <li><FormattedMessage id='analisetecnica.mean' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.mean}</li>
          <li><FormattedMessage id='analisetecnica.medi' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.median}</li>
          <li><FormattedMessage id='analisetecnica.min' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.minimum}</li>
          <li><FormattedMessage id='analisetecnica.sd' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.standard_deviation}</li>
          <li><FormattedMessage id='analisetecnica.vari' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.statistics.variance}</li>
        </ul>
        <p><FormattedMessage id='analisetecnica.symbol' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.symbol}</p>
        <p><FormattedMessage id='analisetecnica.ti' /> </p>
        <ul>
          <li><FormattedMessage id='analisetecnica.ema20' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.ema20}</li>
          <li><FormattedMessage id='analisetecnica.ema200' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.ema200}</li>
          <li><FormattedMessage id='analisetecnica.ema50' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.ema50}</li>
          <li><FormattedMessage id='analisetecnica.sma20' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.sma20}</li>
          <li><FormattedMessage id='analisetecnica.sma200' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.sma200}</li>
          <li><FormattedMessage id='analisetecnica.sma50' /> {dados['-NUlNMPLPf4T8_FCdTh1']?.technical_indicators.sma50}</li>
        </ul>
      </div>
    </center>
  );
}

export default AnalysisComponent;