import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import botcover_img from '../bot_cover1.png';
import axios from 'axios';
import { useUserContext } from '../contexts/UserContext';
import { UserProvider } from '../contexts/UserContext';

function CadastroUsuario() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orderID, setOrderID] = useState('');
  const [error, setError] = useState(null);
  const [showOrderID, setShowOrderID] = useState(false);
  const [userFound, setUserFound] = useState(false);

  const navigate = useNavigate();
  const { login } = useUserContext();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderLogin = urlParams.get('orderLogin');
    if (orderLogin) {
      setOrderID(orderLogin);
      setShowOrderID(true);
    }
  }, []);

  async function fetchLoginData() {
    try {
      const response = await axios.get('https://tradelogs-553b7-default-rtdb.firebaseio.com/DB_LOGIN.json');
      const data = response.data;
      const foundUser = Object.values(data).find(user => user.email === email && user.password === password);
      if (foundUser) {
        setUserFound(true);
        login(foundUser);
        navigate('/');
      } else {
        setUserFound(false);
        if (username && email && password) {
          const userData = { username, email, password };
          if (orderID) {
            userData.orderID = orderID;
          }
          const postResponse = await axios.post('https://tradelogs-553b7-default-rtdb.firebaseio.com/DB_LOGIN.json', userData);
          console.log('Usuário inserido ok', postResponse.data);
          login(userData);
          navigate('/');
        }
      }
    } catch (error) {
      setError(error);
    }
  }

  const handleCheckUser = async (event) => {
    event.preventDefault();
    if (orderID === '') {
      const response = await axios.get('https://tradelogs-553b7-default-rtdb.firebaseio.com/DB_LOGIN.json');
      const data = response.data;
      const foundUser = Object.values(data).find(user => user.email === email && user.password === password);
      if (foundUser) {
        login(foundUser);
        navigate('/');
      } else {
        setError(new Error('User NOT found.'));
      }
    } else {
      fetchLoginData();
    }
    setShowOrderID(true);
  }

  return (<UserProvider>
    <div style={{ color: 'white' }}>
      <br />
      <img src={botcover_img} />
      <h3><FormattedMessage id="loginpage.text1" /></h3>
      <form onSubmit={handleCheckUser}>
        <label>
          Username:<br />
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required /><br />
        </label>
        <label>
          E-mail:<br />
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required /><br />
        </label>
        <label>
          Password:<br />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br />
        </label><br /><br />
        {showOrderID && (
          <label>
            Order ID:<br />
            <input type="text" value={orderID} onChange={(e) => setOrderID(e.target.value)} disabled={true} /><br /><br />
          </label>
        )}
        <button type="submit" style={{ cursor: 'pointer' }}><FormattedMessage id="botao.login" /></button>
        {error && <div>{error.message}</div>}
      </form><br /><br />
      <FormattedMessage id='loginpage.text.createaccount' /> <a href='https://bitcointraderbots.com/paypal' style={{color:'#4ae'}} >https://bitcointraderbots.com/paypal</a>
    </div>
    </UserProvider>
  );
}

export default CadastroUsuario;
