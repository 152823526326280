import './App.css';
import NavbarTop from './components/COMP_navbar_top2';
import Rodape from './components/Rodape';
import { IntlProvider } from 'react-intl';
import pt_BR from './translations/pt_BR.json';
import en_US from './translations/en_US.json';
import fr_FR from './translations/fr_FR.json';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PG_HOME from './components/HOME_1';
import CadastroUsuario from './components/CadastroDeUsuario';
import MensagemBoasVindas from './components/CadastroDeUsuarioLink';
import VerificarOrderId from './components/Verifica_OrderID';
import { useUserContext } from './contexts/UserContext';
import { UserProvider } from './contexts/UserContext';
import AboutPage from './components/About';
import VideoPlayer from './components/comp_player_bot1';



const messages = {
'pt-BR': pt_BR,
'en-US': en_US,
'fr-FR': fr_FR,
};

function App() {
const [locale, setLocale] = useState('en-US');
const userContext = useUserContext();
const isLoggedIn = userContext && userContext.isLoggedIn;

useEffect(() => {
const browserLocale = navigator.language;
setLocale(browserLocale);
}, []);

return (
<UserProvider>
<IntlProvider locale={locale} messages={messages[locale]}>
<Router>
<div className="App">
  <NavbarTop isLoggedIn={isLoggedIn} />
  <br /><br /><br />

  <Routes>
    <Route path="/" exact element={<PG_HOME />} />
    <Route path="/login" element={<CadastroUsuario />} />
    <Route path="/cadastrolink" element={<MensagemBoasVindas />} />
    <Route path="/verificar-order-id" element={<VerificarOrderId />} />
    <Route path="/about" exact element={<AboutPage />} />
  </Routes>

      <VerificarOrderId />
      <VideoPlayer />
      <Rodape />
  </div>
</Router>
</IntlProvider>


</UserProvider>);
}

export default App;