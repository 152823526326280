import React from 'react';
import bot1_img from '../bot1_img.png';
import bot2_img from '../bot2_img.png';
import bot3_img from '../bot3_img.png';
import bot4_img from '../bot4_img.png';
import bot5_img from '../bot5_img.png';
import bot6_img from '../bot6_img.png';

import WalletInfo from './bot_wallet_profits';
import WalletInfo_bot2 from './polobot2/bot_wallet_profits';
import WalletInfo_bot3 from './polobot3/bot_wallet_profits';
import WalletInfo_BinaBot1 from './binabot1/bot_wallet_profits';
import WalletInfo_BinaBot2 from './binabot2/bot_wallet_profits';
import WalletInfo_BinaBot3 from './binabot3/bot_wallet_profits';

import './topbar_divs.css';


const MeuComponenteBots = () => {
  const estiloDoComponente = {
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

  };

  const estiloDaDivPai = {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  };

  const estiloDaDivFilha = {
    height: '90%',
    width: '30%',
    backgroundColor: '#ccc8',
    borderRadius: '10px',
    border: '1px solid yellow', // adicionando a borda
  };
  
  const estiloDaDivFilhaPolo = {
    height: '90%',
    width: '30%',
    backgroundColor: '#ccc8',
    borderRadius: '10px',
    border: '1px solid lime', // adicionando a borda
  };
  
  return (
    <div style={estiloDoComponente}>
      <div style={estiloDaDivPai}>
        <div style={estiloDaDivFilha}  className="borda-piscante">
            <table>
            <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot1_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot1 />
                
                
                </td>
              </tr>
              </tbody>
            </table>
            
        </div>
        <div style={estiloDaDivFilha}  className="borda-piscante">
          <table>
          <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot2_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot2 />
                
                </td>
              </tr>
              </tbody>
          </table>
        </div>
        <div style={estiloDaDivFilha}  className="borda-piscante">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot3_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot3 />
                
                </td>
              </tr>
              </tbody>
          </table>
        </div>
      </div>
      <div style={estiloDaDivPai}>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot4_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo />
                
                </td>
              </tr>
              </tbody>
          </table>


        </div>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot5_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_bot2 />
                
                </td>
              </tr>
              </tbody>
          </table>


        </div>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot6_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_bot3 />
                
                </td>
              </tr>
              </tbody>
          </table>

        </div>
      </div>
    </div>
  );
};

export default MeuComponenteBots;
