import React from 'react';
import { FormattedMessage } from 'react-intl';

function AboutPage() {
  return (
    <div style={{color:'white'}}>
      <h3><FormattedMessage id="sobrenos.titulo" /></h3>
      <p><FormattedMessage id="sobrenos.texto1" /></p>
      <p><FormattedMessage id="sobrenos.texto2" /></p>
      <p><FormattedMessage id="sobrenos.texto3" /> <br /><br /> Thank You. More info available soon. </p>
    </div>
  );
}

export default AboutPage;
