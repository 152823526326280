import { createContext, useContext, useState } from 'react';
export const UserContext = createContext(null);
export const useUserContext = () => useContext(UserContext);
export const UserProvider = ({ children, language }) => {
const [user, setUser] = useState(null);
const [isLoggedIn, setIsLoggedIn] = useState(false);
const login = (userData) => {
setUser(userData);
setIsLoggedIn(true);
};
const logout = () => {
setUser(null);
setIsLoggedIn(false);
};
const value = { user, isLoggedIn, login, logout, language };
return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};