import React from 'react';
import bot7_img from '../bot7_img.png';
import bot8_img from '../bot8_img.png';
import bot9_img from '../bot9_img.png';

import bot10_img from '../bot10_img.png';
import bot11_img from '../bot11_img.png';
import bot12_img from '../bot12_img.png';

import WalletInfo_polobot4 from './polobot4/bot_wallet_profits';
import WalletInfo_polobot5 from './polobot5/bot_wallet_profits';
import WalletInfo_polobot6 from './polobot6/bot_wallet_profits';

import WalletInfo_BinaBot4 from './binabot4/bot_wallet_profits';
import WalletInfo_BinaBot5 from './binabot5/bot_wallet_profits';
import WalletInfo_BinaBot6 from './binabot6/bot_wallet_profits';

import './topbar_divs.css';


const MeuComponenteBots2 = () => {
  const estiloDoComponente = {
    height: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

  };

  const estiloDaDivPai = {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  };

  const estiloDaDivFilha = {
    height: '90%',
    width: '30%',
    backgroundColor: '#ccc8',
    borderRadius: '10px',
    border: '1px solid yellow', // adicionando a borda
  };
  
  const estiloDaDivFilhaPolo = {
    height: '90%',
    width: '30%',
    backgroundColor: '#ccc8',
    borderRadius: '10px',
    border: '1px solid lime', // adicionando a borda
  };
  
  return (
    <div style={estiloDoComponente}>
      <div style={estiloDaDivPai}>
        <div style={estiloDaDivFilha}  className="borda-piscante">
            <table>
            <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot7_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot4 />
                
                
                </td>
              </tr>
              </tbody>
            </table>
            
        </div>
        <div style={estiloDaDivFilha}  className="borda-piscante">
          <table>
          <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot8_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot5 />
                
                </td>
              </tr>
              </tbody>
          </table>
        </div>
        <div style={estiloDaDivFilha}  className="borda-piscante">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot9_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_BinaBot6 />
                
                </td>
              </tr>
              </tbody>
          </table>
        </div>
      </div>
      <div style={estiloDaDivPai}>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot10_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_polobot4 />
                
                </td>
              </tr>
              </tbody>
          </table>


        </div>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot11_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_polobot5 />
                
                </td>
              </tr>
              </tbody>
          </table>


        </div>
        <div style={estiloDaDivFilhaPolo}  className="borda-piscante-polo">
        <table>
        <tbody>
              <tr>
                <td style={{verticalAlign:'top'}}><img src={bot12_img} /></td>
                <td style={{verticalAlign:'top'}}>
                <WalletInfo_polobot6 />
                
                </td>
              </tr>
              </tbody>
          </table>

        </div>
      </div>
    </div>
  );
};

export default MeuComponenteBots2;
