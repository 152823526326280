import React, { useState, useEffect } from "react";
import axios from "axios";

const Advisor_polobot5 = () => {
  const [ultimoRegistro, setUltimoRegistro] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tradelogs-553b7-default-rtdb.firebaseio.com/tradestatus_advisor_polobot5.json"
        );
        const registros = Object.values(response.data);
        const ultimo = registros[registros.length - 1];
        setUltimoRegistro(ultimo);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const renderizarDados = () => {
    if (!ultimoRegistro) {
      return null;
    }

    if (ultimoRegistro.trade_type === "SELL") {
      return (
        <div style={{ color: "lime", fontSize: "12px" }}>
          Advisor: {ultimoRegistro.msg}
        </div>
      );
    } else if (ultimoRegistro.trade_type === "BUY") {
      return (
        <div style={{ color: "yellow", fontSize: "12px" }}>
          Advisor: {ultimoRegistro.msg}
        </div>
      );
    } else {
      return null;
    }
  };

  return <div>{renderizarDados()}</div>;
};

export default Advisor_polobot5;
