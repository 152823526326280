import React, { useState, useEffect, useContext } from 'react';
import './navbar.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import AboutPage from '../components/About';
import LanguageSelector from './Language_selector2';

function NavbarTop() {
const { isLoggedIn } = useContext(UserContext); // Obter a propriedade "isLoggedIn" do contexto
const orderId = new URLSearchParams(window.location.search).get('orderID');
const [text, setText] = useState('Bitcoin Trader Bots');
const [isTrader, setIsTrader] = useState(true);
const [backgroundColor, setBackgroundColor] = useState('#f11');
const intl = useIntl();
const language = intl.locale;

const blinkBackgroundColor = (color1, color2, interval) => {
let blinkInterval = setInterval(() => {
setBackgroundColor(prevColor => prevColor === color1 ? color2 : color1);
}, interval);
return () => clearInterval(blinkInterval);
}

useEffect(() => {
const intervalText = setInterval(() => {
setIsTrader(!isTrader);
setText(isTrader ? 'Bitcoin Home Broker' : 'Bitcoin Trader Bots');
}, 2000);

const intervalBlink = blinkBackgroundColor('#f11', 'gray', 700);

return () => {
  clearInterval(intervalText);
  intervalBlink();
};

}, [isTrader]);

const registerUrl = language === 'pt-BR' ? 'https://bitcointraderbots.com/pix' : 'https://bitcointraderbots.com/paypal';

return (
<div className="navbar" style={{backgroundColor:'#ccc8'}}>
<div className="navbar__logo">
{text}
</div>
{!isLoggedIn && ( // Verificar se o usuário está logado antes de exibir o menu
<div className="navbar__menu">
<table>
<tr>
<td>
<a href={registerUrl} style={{color:'white', backgroundColor: backgroundColor}}>
<span id='bt_reg'> <FormattedMessage id="botao.register" /> </span>
</a>  &nbsp;&nbsp;
</td>
<td>
<Link to="/login" relative="path">
<font style={{color:'yellow'}}><FormattedMessage id="botao.login" /></font>
</Link>   &nbsp;&nbsp;
</td>
<td>
<Link to="/about" relative="path">
<font style={{color:'black'}}><FormattedMessage id="navbar.botao.about" /></font>
</Link>   &nbsp;&nbsp;
</td>
</tr>
</table>
</div>
)}
{isLoggedIn && ( // Verificar se o usuário está logado antes de exibir o menu
<div className="navbar__menu">
<table>
<tr>
<td>
<Link to="/cfanalytics" relative="path">
<font style={{color:'yellow'}}>CFAnalytics</font>
</Link>   &nbsp;&nbsp;
</td>
<td>
<Link to="/reports" relative="path">
<font style={{color:'yellow'}}>Reports</font>
</Link>   &nbsp;&nbsp;
</td>
<td>
<Link to="/logout" relative="path">
<font style={{color:'black'}}>LogOut</font>
</Link>   &nbsp;&nbsp;
</td>
</tr>
</table>
</div>
)}
</div>
);
}

export default NavbarTop;