import React, { useState, useEffect } from 'react';
import Advisor_binabot1 from './firebase_binance_advisor_bot1';
import { useUserContext } from '../../contexts/UserContext';
import { FormattedMessage } from 'react-intl';

function WalletInfo_BinaBot1() {
  const [records, setRecords] = useState([]);
  const userContext = useUserContext();
  const isLoggedIn = userContext ? userContext.isLoggedIn : false;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://tradelogs-553b7-default-rtdb.firebaseio.com/wallet_binabot1.json');
        const data = await response.json();
        const recordList = Object.values(data);
        setRecords(recordList);

      } catch (error) {
        console.error(error);

      }
    }
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {records.length > 0 ? (
        <div style={{ fontSize: '12px', textAlign: 'left' }}>
          <strong style={{ fontSize: '12px', textAlign: 'left', color: 'yellow' }}>Binance BOT1</strong><br />
          Profit BTC: {records[records.length - 1].profit_btc} <br />
          Profit in USDT: {records[records.length - 1].profit_usdt} <br />
          Trades: {records[records.length - 1].trades}
          {isLoggedIn ? <Advisor_binabot1 /> :  <span style={{color:'lime'}}><br/><FormattedMessage id='advisor.loginfirst' /></span> }
          <br /><br /><br /><br />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default WalletInfo_BinaBot1;
