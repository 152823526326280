import React from 'react';

function Rodape() {
  return (
    <footer style={{ position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: 'black', color: 'lime', fontSize:10, width: "100%" }}>
      <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th style={{ width: "100%", verticalAlign:'left'}} >Bitcoin Trader Bots Developed by: DanielCruz</th>

        </tr>
      </thead>
      <tbody>
       
      </tbody>
    </table>
    </footer>
  );
}

export default Rodape;
