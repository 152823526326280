import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function VerificarOrderId() {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o parâmetro orderID existe na URL
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('orderID');
    const order_Login = urlParams.get('orderLogin'); // after payment ok
    
    if (orderId) {
      navigate(`/cadastrolink?orderID=${orderId}`);
    }
    if (order_Login) {
      navigate(`/login?orderLogin=${order_Login}`);
    }
  }, [navigate]);

  return (
   <></>
  );
}

export default VerificarOrderId;
