import React from 'react';
import { FormattedMessage } from 'react-intl';

function MensagemBoasVindas() {
  const orderID = new URLSearchParams(window.location.search).get('orderID');

  function handleClick() {
    window.open('https://bitcointraderbots.web.app/?orderLogin='+ orderID , '_blank');
  }

  return (
    <div style={{color:'white'}}>
      <h3><FormattedMessage id="mensagem.order.titulo" /></h3>
      <p><FormattedMessage id="mensagem.order.titulo.save" /> <br /> {orderID}</p>
      <br />
      <p><FormattedMessage id="mensagem.order.titulo.click" /></p>
      <a href="#" onClick={handleClick}><FormattedMessage id="mensagem.order.titulo.btlogin" /></a>
      <br />
      <br />
      <br />
    </div>
  );
}

export default MensagemBoasVindas;
